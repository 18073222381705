<template>
  <div class="menu_box2">
    <div class="main_menu">
      <div class="menu_box container">
        <span
          v-for="(item, index) in menu_list"
          :key="index"
          class="menu_item"
          @click="changeRoute(item)"
          :class="menu_id === item.id ? 'choose_it' : ''"
        >
          <img
            :src="imgList['nav' + item.id + (menu_id === item.id ? '_w' : '')]"
            alt=""
          />
          <span>{{ item.name }}</span>
        </span>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "menuList",
  data() {
    return {
      menu_list: [
        { name: "推荐", id: 1, path: "/main" },
        { name: "书库", id: 2, path: "/stack_room" },
        { name: "排行", id: 3, path: "/ranking" },
        { name: "个人中心", id: 4, path: "/owner_center" },
        { name: "下载APP", id: 5, path: "/download" },
      ],
      imgList: {
        nav1: require("./../../assets/images/index/nav1.png"),
        nav2: require("./../../assets/images/index/nav2.png"),
        nav3: require("./../../assets/images/index/nav3.png"),
        nav4: require("./../../assets/images/index/nav4.png"),
        nav5: require("./../../assets/images/index/nav5.png"),
        nav1_w: require("./../../assets/images/index/nav1.png"),
        nav2_w: require("./../../assets/images/index/nav2.png"),
        nav3_w: require("./../../assets/images/index/nav3.png"),
        nav4_w: require("./../../assets/images/index/nav4.png"),
      },
      menu_id: "",
    };
  },
  watch: {
    $route() {
      this.change_menu();
    },
  },
  created() {
    this.change_menu();
  },
  mounted() {},
  methods: {
    //点击菜单跳转
    changeRoute(row) {
      if (row.id === 4) {
        // if (!this.getCookie("api_token")) {
        if (!localStorage.getItem("api_token")) {
          this.$store.commit("set_login_type", true);
        } else {
          sessionStorage.setItem("menu_id", row.id);
          this.menu_id = row.id;
          let mine_menu = {
            id: 1,
            link: "/mine_center",
            name: "个人中心",
          };
          this.$store.commit("set_mine_menu", mine_menu);
          this.$router.push({
            path: `${row.path}`,
          });
        }
      } else {
        sessionStorage.setItem("menu_id", row.id);
        this.menu_id = row.id;
        this.$router.push({
          path: `${row.path}`,
        });
      }
    },
    change_menu() {
      let id = Number(sessionStorage.getItem("menu_id"));
      // let id = this.menu_list[0].id
      if (id) {
        this.menu_id = id;
      } else {
        this.menu_id = this.menu_list[0].id;
      }
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../utils/tool.scss"; //引入公共css类
.menu_box2 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.main_menu {
  width: 100%;
  background-color: #fff;
  //border: 1px solid #ffffff;
  box-shadow: 0 5px 5px -5px rgba(153, 153, 153, 0.4);
  // margin-bottom: 5px;
  position: relative;
  z-index: 100;
  font-size: 16px;

  .menu_box {
    display: flex;
    width: 1200px;
    margin: 0 auto;

    > span {
      display: inline-block;
      // width: 130px;
      padding: 11px 0;
      cursor: pointer;
      text-align: center;
      margin-right: 48px;
      img {
        height: 22px;
      }
    }

    > span:hover {
      color: #409eff;
    }
    .menu_item {
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        display: block;
        width: 38px;
        height: 38px;
        margin-right: 12px;
        margin-bottom: 3px;
      }
      .check {
        margin-bottom: 0px;
        width: 8px;
        height: 5px;
        display: none;
      }
    }
  }
}
//.menu_scoll {
//  .main_menu {
//    width: 100%;
//    .menu_item {
//      height: 56px;
//      line-height: 32px;
//      img {
//        display: none;
//      }
//    }
//  }
//}

.choose_it {
  font-weight: bold;
}
.menu_scoll .choose_it {
  font-weight: bold;
}
</style>
