<template>
  <main class="index">
    <header class="header_box" :class="header_scoll ? 'header_scoll' : ''">
      <topHeader :nav_type="header_scoll"></topHeader>
    </header>
    <section class="index_bc">
      <img
        :src="get_top_banner_info.img_url"
        alt=""
        @click="to_link(get_top_banner_info)"
      />
      <!--            <img src="./../../assets/images/index/headbg.png" alt=""/>-->
    </section>
    <section class="main_content">
      <div ref="menu_list" class="menu_list">
        <!--        :class="menu_scoll ? 'menu_scoll' : ''"-->
        <menu-list></menu-list>
      </div>
      <router-view />
    </section>
    <!-- 底部 -->
    <div class="jy-main-bottom">
      <mainbottom :options="options_bottom"></mainbottom>
    </div>
    <div class="bottom" flex center align>
      <div class="btottom-center">
        <div class="bottom-left">
          <ul>
            <li>
              <img src="../../assets/images/police.png" alt="" /><span
                >公安网备</span
              >
            </li>
            <li></li>
            <li><span>网文许可证</span></li>
            <li></li>
            <li><span>电信增值业务许可证</span></li>
            <li></li>
            <li><span>ICP备案</span></li>
          </ul>
          <div>
            <p>川公网安备 51019002002293号</p>
            |
            <p>川网文 [2020]2152-468号</p>
            |
            <p>增值电信业务经营许可证川 B2-20201265</p>
            |
            <p class="bei" @click="goBeian()">蜀ICP备18031577号-2</p>
          </div>
        </div>
        <div>
          <div>
            <a
              target="cyxyv"
              href="https://v.yunaq.com/certificate?domain=book.jyacg.com&from=label&code=90030"
            >
              <img src="https://aqyzmedia.yunaq.com/labels/label_lg_90030.png"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import menuList from "@/components/main/menu";
import topHeader from "@/components/main/top-header";
import mainbottom from "../main/main-bottom";
import { home_one } from "@/https/home.js";
import { home_two } from "@/https/home.js";
import { mapGetters } from "vuex";

export default {
  name: "index",
  components: {
    menuList,
    topHeader,
    mainbottom,
  },
  data() {
    return {
      large_banner: {},
      header_scoll: false,
      menu_scoll: false,
      pic_height: 0,
      options_bottom: {
        title: [],
        footer_photo: [],
      },
      options_main: {
        data_l: [],
        data_r: [],
        icon: "icon-tuijian",
        style: "color:rgb(30, 194, 255);font-size:25px",
      },
    };
  },
  created() {
    this.load_data_two();
  },
  computed: {
    ...mapGetters(["get_top_banner_info"]),
  },
  methods: {
    get_lar_banner() {
      home_one().then((res) => {
        this.large_banner = res.data.long_banner;
      });
    },
    load_data_two() {
      home_two().then((res) => {
        // 底部信息
        this.options_bottom.title = [
          res.data.title1,
          res.data.title2,
          res.data.title3,
          res.data.title4,
        ];
        this.options_bottom.footer_photo = res.data.footer_photo;
        this.options_bottom.footer_photo = this.options_bottom.footer_photo.splice(
          0,
          4
        );
        // 最新更新榜
        // this.optionsArr[2].data_l = res.data.update_top;
        // this.optionsArr[2].data_l = this.optionsArr[2].data_l.splice(0, 12);
        // // vip热门榜
        // this.optionsArr[3].data_l = res.data.vip_data;
        // this.optionsArr[3].data_l = this.optionsArr[3].data_l.splice(0, 12);
        // // 主力推荐
        // this.options_main.data_l = res.data.main_recommend;
        // this.options_main.data_l = this.options_main.data_l.splice(0, 6);
      });
    },
    to_link(item) {
      if (item.content) {
        window.open(item.content);
      }
    },
    goBeian() {
      window.open("https://beian.miit.gov.cn");
    },
    windowScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let scroll_dom =
        document.getElementsByClassName("index_bc")[0].offsetHeight - 56;
      this.pic_height = document.getElementsByClassName(
        "index_bc"
      )[0].offsetHeight;
      if (scrollTop > 0) {
        this.header_scoll = true;
      } else {
        this.header_scoll = false;
      }
      if (scrollTop > scroll_dom) {
        this.menu_scoll = true;
      } else {
        this.menu_scoll = false;
      }
    },
  },
  mounted() {
    this.get_lar_banner();
    window.addEventListener("scroll", this.windowScroll);
  },
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
  font-size: 14px;
}

.header_box {
  position: absolute;
  width: 100%;
}

.index {
  width: 100%;
  height: 100%;
  position: relative;
}

/deep/ .top_header {
  background: rgba(0, 0, 0, 0) !important;
}

.index_bc {
  background-color: #fafcff;
  width: 100%;
  font-size: 0px;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    min-height: 100px;
    // height: calc(100vh * 19.2%);
  }
}

.main_content {
  background-color: #fafcff;
  min-height: calc(100vh - 220px);

  .main_menu {
    background-color: #fff;
  }
}

/deep/ .input_arr {
  opacity: 0.8;
}

.header_scoll {
  position: fixed;
  top: 0;
  z-index: 201;
  width: 100%;
  background: #ffffff !important;
  box-shadow: 0 5px 5px -5px rgba(153, 153, 153, 0.4);

  /deep/ .top_header {
    background: #ffffff !important;
  }
}

.menu_list_copy {
  display: none;
  visibility: hidden;

  &.show {
    display: block;
    visibility: hidden;
  }
}

.menu_scoll {
  position: fixed;
  top: 56px;
  width: 100%;
  z-index: 200;
  border-top: 1px solid #f1f1f1;
}

/deep/ .jy-main-bottom {
  width: 100%;
  background: #2b3339;
  padding-bottom: 12px;
}

// .jy-main-hrp {
//   width: 45%;
//   height: 0.5px;
//   background-color: #d1cdcd;
//   border: none;
// }
// .jy-main-hrif {
//   width: 12%;
//   padding: 5px;
//   color: #333;
//   font-size: 18px;
//   .iconfont::before {
//     margin-right: 8px;
//     color: rgb(27, 123, 251);
//     font-size: 30px;
//   }
// }
/deep/ .bottom {
  background: #2b3339;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;

  .btottom-center {
    width: 1200px;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    justify-content: space-around;
    .bottom-left {
      div {
        width: 850px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 16px;
        line-height: 16px;
        font-size: 12px;
        color: #999999;
        margin-top: 11px;
        .bei {
          cursor: pointer;
        }
      }
    }

    ul {
      width: 850px;
      display: flex;
      justify-content: space-around;

      li {
        list-style: none;
        height: 19px;
        line-height: 19px;
        font-size: 14px;

        color: #ffffff;
      }

      li:nth-of-type(1) {
        display: flex;
        align-items: center;
      }
    }

    a {
      display: flex;
      align-items: center;
      img {
        width: 120px;
      }
    }
  }
}
</style>
